.PlayerCard {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    margin: 0 10px;
    padding: 10px;
    width: 100px;
    aspect-ratio: 1/1;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.PlayerCard img {
    width: 100%;
    /* Assuming you'll have images */
    border-radius: 10px;
    margin-bottom: 8px;
}

.PlayerCard h3 {
    margin: 0;
    font-size: 1.2em;
}

.PlayerCard p {
    margin: 0;
    font-size: 0.9em;
}

/* Add styles for health and mana bars */

.bar-container {

    bottom: 20px;
    width: 100%;
    height: 10px;
    overflow: hidden;
    position: relative;

}

.health-bar,
.mana-bar {
    position: absolute;
    bottom: 0;
    /* position at the bottom of the card */
    left: 0;
    height: 5px;
    /* height of the bar */
    width: 100%;
}

.health-bar {
    background-color: rgb(81, 241, 49);
    /* color for the health bar */
}

.mana-bar {
    background-color: blue;
    /* color for the mana bar */
    bottom: 5px;
    /* offset from the bottom so it doesn't overlap with the health bar */
}