/* Map.css */
.Map {
    background-color: rgba(150, 150, 150, 0.4);
    padding: 10px;
    position: absolute;
    top: 40px;
    right: 40px;
    width: auto;
    border-radius: 10px;
    overflow: hidden;

    z-index: 1000;
}

.Map img {
    width: 150px;
    height: auto;
    /* maintain aspect ratio */
    object-fit: cover;
    /* Show the entire map within the div */
}