.App {
  background-color: #222;
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* margin-right: 250px; */
  padding-right: 330px;
  /* Sidebar width */

}

h1 {
  color: rgb(255, 255, 255, 0.7);
  font-size: 3rem;
  padding: 0;
  margin: 31px 5px 0 0;
  display: inline-block;
}

span {
  color: rgb(255, 255, 255, 0.3);
}

.App-header {
  color: white;
  padding: 0px 20px 9px 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}