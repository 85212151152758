/* ProfilePic.css */
.ProfilePic {
    position: fixed;
    /* Or 'absolute' if within a relative container */
    top: 20px;
    /* Adjust as needed for your layout */
    right: 20px;
    /* Adjust as needed for your layout */
    width: 50px;
    /* Size of the picture */
    height: 50px;
    /* Size of the picture */
    border-radius: 50%;
    /* Make it round */
    overflow: hidden;
    /* Ensure the image doesn't overflow the circle */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Optional: adds depth with a shadow */
}

.ProfilePic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensure the image covers the area */
}