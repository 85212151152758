.PlayerPanel {
    position: relative;
    background-color: #222;
    padding: 10px;
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    /* overflow: hidden; */
}

.PlayerCard {
    transition: transform 0.3s ease;
    margin-right: 10px;
    /* Adjust spacing between cards */
    opacity: 0;
    /* Start with card invisible */
    transform: translateX(-20px);
    /* Start slightly to the left */
    animation: slideIn 0.3s forwards;
    /* Smooth transition for the sliding effect */
    /* ... other styles ... */
}

@keyframes slideIn {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Add to your playerPanel.css or a separate CSS file where you handle button styles */
.AddPlayerButton {
    position: relative;
    top: calc(-100% + 40px);
    right: calc(-100% + 10px);
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #181818;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.AddPlayerButton:hover {
    background-color: #696969;
    /* Darker gray on hover */
}

/* Optional: remove default button styling */
.AddPlayerButton:focus {
    outline: none;
}