.Sidebar {
    margin-top: 104px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    color: white;
    width: 260px;
    height: calc(100% - 160px);
    position: fixed;
    top: 0;
    right: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* This ensures the chat input is at the bottom */

    /* Dark background color as per design */
    color: white;
    /* Text color */

}

.Tab {

    border: 1px solid #333;
    cursor: pointer;
    display: inline-block;
    padding: 10px;
    margin-right: 5px;
    /* Space between tabs */
    background-color: #333;
    /* Tab background color */
    border: none;
    cursor: pointer;
}

.Tab:hover {
    background-color: #555;
    /* Tab hover color */
}


/* Styles for Chat Feed */
.ChatFeed {
    background-color: #0e0e0e;
    overflow-y: auto;
    border-radius: 10px;
    padding: 10px;
    height: calc(100% - 120px);
    /* Adjust based on the total height of the sidebar minus input box */
    margin-bottom: 10px;
    /* Space above the input box */
}

/* Styles for Chat Input */
.ChatInput {
    display: flex;
}

.ChatInput input {
    background-color: #0e0e0e;
    border-radius: 10px 0 0 10px;
    color: white;
    flex: 1;
    padding: 10px;
    border: none;
    margin-right: 5px;
    /* Space between input box and send button */
}

.ChatInput button {
    border-radius: 0px 10px 10px 0px;

    padding: 10px;
    background-color: #808080;
    /* Button background color */
    color: white;
    border: none;
    cursor: pointer;
}

.ChatInput button:hover {
    background-color: #555;
    /* Button hover color */
}