.MainGameView {
    flex-grow: 1;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 20px;
    padding-bottom: 0;
    width: 100%;
    /* Adjust if necessary */
    height: 100%;
    /* Adjust if necessary */

}

.MainGameViewContent {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    width: 100%;
    /* Adjust if necessary */
    height: 100%;
    /* Adjust if necessary */
    padding: 10px;

}

.GameScreen {
    position: relative;
    width: 100%;
    height: calc(100% - 50px);
    background-size: cover;
    background-position: center;
    border-radius: 10px;

}

.TextOutput {
    position: absolute;
    bottom: 6px;
    left: .5%;
    width: 99%;
    background-color: rgb(0, 0, 0, 0.7);
    color: white;
    font-size: 1.4em;
    border-radius: 10px;

}

.TextOutput p {
    padding: 10px;
}


.TextInput {
    display: flex;
    margin-top: 10px;
    z-index: 999999;
}


.TextInput input {
    background-color: #0e0e0e;
    border-radius: 10px 0 0 10px;
    color: white;
    flex: 1;
    padding: 10px;
    border: none;
    z-index: 9999;

    /* width: 100%; */
    /* margin-right: 5px; */
    /* Space between input box and send button */
}

.TextInput button {
    border-radius: 0px 10px 10px 0px;
    padding: 10px;
    background-color: #808080;
    /* Button background color */
    color: white;
    border: none;
    cursor: pointer;
}